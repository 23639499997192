import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { mapDefaultTasq } from '@/utils/tasqs';

import { mapDefaultProducingTasq } from '@/utils/producingTasqs';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import predictionsApolloClient from '@/lib/appsync/workflow';
import getTasqsLists from '@/graphql/predictions/mutations/getTasqsLists.graphql';
import searchTasqs from '@/graphql/predictions/mutations/searchTasqsList.graphql';
import getMyWaitList from '@/graphql/workflow/queries/getMyWaitList.graphql';
import getTasq from '@/graphql/workflow/queries/getTasq.graphql';
import getTasqEventHistory from '@/graphql/workflow/queries/getTasqEventHistory.graphql';
import getAllTasqResponses from '@/graphql/workflow/queries/getAllTasqResponses.graphql';
import { Debounce } from 'vue-debounce-decorator';
import { getConfigEnv, jsonParse, sleep } from '@/utils/helpers';
import GetTasqsListsResponse from '@/graphql/predictions/interfaces/GetTasqsListsResponse';
import SearchTasqsResponse from '@/graphql/predictions/interfaces/SearchTasqsListsResponse';
import TasqJobResponse from '@/graphql/predictions/interfaces/TasqJobResponse';
import accountModule from '@/store/modules/accountModule';
import workflowApolloClient from '@/lib/appsync/workflow';
import userPreferenceDB from '@/lib/userPreference';
import getSubmittedForms from '@/graphql/workflow/queries/getSubmittedForms.graphql';
// import workflowModule from '@/store/modules/workflowModule';
// import tasqsListModule from '@/store/modules/tasqsListModule';
// import isOnline from 'is-online';
import localForage from '@/lib/localForage';
// import userPreferenceDB from '@/lib/tasqListOffline';
import workspaceExample from '@/router/routes/workspaceExample';
import isOnline from 'is-online';
import { mapDefaultWorkTicketTasqs } from '@/utils/workTicketTasqs';
import getWorkTickets from '@/graphql/workflow/queries/getWorkTicketTasqs.graphql';
import getCountTasqForJobTypes from '@/graphql/workflow/queries/getCountOpenTasqsForJobType.graphql';
import getCountTasqForAsset from '@/graphql/workflow/queries/getCountOpenTasqsForAsset.graphql';
import workspaceModule from './workspaceModule';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqsListModule',
  store,
})
class TasqsListModule extends VuexModule {
  tasqEventHistoryList: any[] = [];

  completedTasqList: TasqJob[] = [];

  waitingTasqList: TasqJob[] = [];

  tasqList: TasqJob[] = [];

  allTasqs: TasqJob[]| any = [];

  padNestedTasqs: TasqJob[] = [];

  producingWells: TasqJob[] = [];

  additionalPadNestedTasqs: TasqJob[] = [];

  activeTasq: TasqJob|null|undefined = null;

  activeWorkTicket: TasqJob|null|undefined = null;

  activeOfflineTasq: TasqJob|null|undefined = null;

  activeProducingTasq: TasqJob|null|undefined = null;

  activePage = '';

  kanbanSelectedTasq: any = '';

  showTasqDetailsMobile = false;

  activeFilterRoute: any = [];

  activeFilterWaitingOn: any = [];

  isLoadingTasqResponses = false

  isLoadingProducingData = false

  activeSubstringFilter = '';

  activeListType = '';

  tasqListLevel = 'Well'

  activeFilterList: any = null;

  signalChartTime = 60;

  isLoadingTasqs = false

  isLoadingPaginatedTasqs = false

  isLoadingSearchTasqs = false

  activeTasqScroll: any = null;

  tasqStartLimit: any = 0;

  tasqEndLimit: any = 30;

  progressPercentage: any = 0;

  savingForOffline = false;

  defermentDays: any = 0;

  signalChartTimeObj: any = null;

  cycleTimeLessThanFilter: any = null;

  unitsGreaterThanFilter: any = null;

  unitsLessThanFilter: any = null;

  cycleTimeGreaterThanFilter: any = null;

  defermentPercentLessThanFilter: any = null;

  defermentPercentGreaterThanFilter: any = null;

  cycleTimeDays: any = 0;

  jobTypeFilter: any = [];

  tasqLimitPerPage: any = 30;

  tasqTotalPages: any = 1;

  usernamesList: any = [];

  paginationLoading: any = true;

  batchResponseEnabled: any = false;

  fromNowBack = 0;

  activeFilterTeam = [];

  activeFilterArea = [];

  isBatchResponding: boolean = false

  batchResponseDisabledMsg = 'Please select at least 1 tasq';

  currentTasqListPageNo: any = 1;

  todaysTasqLength: any = 0;

  infiniteScrollHeight: any = 0;

  activePredictionTypeFilter: any = []

  allPredictionTypes: any = []

  allPredictionDistTypes: any = []

  tasqResponseResults: any = []

  checkedTasqs: any = []

  isEditing: boolean = false

  isBatchReassign: boolean = false;

  isActionUnReleated = false;

  enablePreloading: boolean = false;

  chartFromTime: any = ''

  chartToTime: any = ''

  useLocalFilter = false;

  submittedTasqs: any = []

  pendingTasqs: any = []

  pendingTasqsPromise: any = []

  isInitialPageLoad = true

  isHistorySectionOpen = false;

  @Mutation
  setIsInitialPageLoad(val): void {
    this.isInitialPageLoad = val;
  }

  @Mutation
  setIsHistorySectionOpen(state): void {
	  this.isHistorySectionOpen = state;
  }

  @Mutation
  setIsEditing(val): void {
    this.isEditing = val;
  }

  @Mutation
  pushSubmittedTasq(tasq): void {
    this.submittedTasqs.push(tasq);
  }

  @Mutation
  async pushPendingTasq(tasq) {
    this.pendingTasqs.push(tasq);
  }

  @Mutation
  setPendingTasq(data): void {
    this.pendingTasqs = data;
  }

  @Mutation
  async pushPendingTasqPromise(tasq) {
    this.pendingTasqsPromise.push(tasq);
    await window.localStorage.setItem('offline-pending-tasqs', JSON.stringify(this.pendingTasqsPromise));
  }

  @Mutation
  setPendingTasqPromise(data): void {
    this.pendingTasqsPromise = data;
  }

  @Mutation
  setIsBatchResponding(val): void {
    this.isBatchResponding = val;
  }

  @Mutation
  setIsBatchReassign(val): void {
    this.isBatchReassign = val;
  }

  @Mutation
  setIsActionUnReleated(val): void {
    this.isActionUnReleated = val;
  }

  @Mutation
  setEablePreloading(value): void {
    this.enablePreloading = value;
  }

  // @Mutation
  // setActiveOfflineTasq(tasq): void {
  //   this.activeOfflineTasq = tasq;
  // }

  @Mutation
  setTasqEventHistoryList(event_list): void {
    const event_list_results: any[] = [];
    for (let x = 0; x < event_list.length; x++) {
      if (JSON.parse(event_list[x]).type !== 'JOB_LABELED') {
        event_list_results.push(JSON.parse(event_list[x]));
      }
    }
    event_list_results.sort((a, b) => b.time.localeCompare(a.time));
    this.tasqEventHistoryList = event_list_results;
  }

  @Mutation
  setBatchResponseEnabled(): void {
	  const checkedFullTasqResponses: any[] = [];
    // console.log('batch')
    // console.log(this.checkedTasqs)
    const allTasqs = [...this.tasqList, ...this.producingWells, ...this.completedTasqList, ...this.waitingTasqList, ...this.padNestedTasqs, ...this.additionalPadNestedTasqs];
	  for (var x = 0; x < this.checkedTasqs.length; x++) {
		  // eslint-disable-next-line no-loop-func
		  const checkedTasq = allTasqs.find((t) => t.id.toLowerCase() === this.checkedTasqs[x].toLowerCase());
      // console.log(checkedTasq)
		  let didAddResponseData = false;
		  for (let y = 0; y < this.tasqResponseResults.length; y++) {
        if (this.checkedTasqs[x] == this.tasqResponseResults[y].PredictionID) {
          // @ts-ignore
				checkedTasq!.ResponseData = this.tasqResponseResults[y].ResponseData;
				didAddResponseData = true;
				break;
        }
		  }
		  if (!didAddResponseData && checkedTasq) {
        // @ts-ignore
        checkedTasq.ResponseData = { UserResponse: {} };
		  }

		  checkedFullTasqResponses.push(checkedTasq);
      // console.log(checkedFullTasqResponses)
	  }

	  let predictionType = null;
	  let responseData = null;
	  for (var x = 0; x < checkedFullTasqResponses.length; x++) {
		  if (checkedFullTasqResponses[x].predictionType == 'Producing') {
        this.batchResponseEnabled = false;
        this.batchResponseDisabledMsg = 'Cannot respond to type Producing.';
		  }
		  if (predictionType == null) {
        predictionType = checkedFullTasqResponses[x].predictionType;
        if (checkedFullTasqResponses[x].ResponseData) {
          responseData = checkedFullTasqResponses[x].ResponseData.UserResponse;
        }
		  } else {
        if (checkedFullTasqResponses[x].predictionType != predictionType) {
          this.batchResponseEnabled = false;
          this.batchResponseDisabledMsg = 'Tasq types must match to perform batch responses.';
          return;
        }
        if (checkedFullTasqResponses[x].ResponseData && JSON.stringify(checkedFullTasqResponses[x].ResponseData.UserResponse) != JSON.stringify(responseData)) {
          this.batchResponseEnabled = false;
          this.batchResponseDisabledMsg = 'Existing responses for the selected tasqs do not match.';
          return;
        }
		  }
	  }
	  this.batchResponseEnabled = true;
	  this.batchResponseDisabledMsg = '';
  }

  @Mutation
  checkTasq(tasqId): void {
    // console.log(tasqId);
    if (this.checkedTasqs.includes(tasqId)) {
      const index = this.checkedTasqs.indexOf(tasqId);
      if (index > -1) {
        this.checkedTasqs.splice(index, 1);
      }
    } else {
      this.checkedTasqs.push(tasqId);
    }
  }

  @Mutation
  updateTasqDetails(tasq): void {
    const { id } = tasq;
    if (this.allTasqs.includes(id)) {
      const index = this.allTasqs.indexOf(id);
      if (index > -1) {
        this.allTasqs.splice(index, 1);
        this.allTasqs.push(tasq);
      }
    }
  }

  @Mutation
  resetChekedTasqs(): void {
	  this.checkedTasqs = [];
  }

  @Mutation
  setTasqResponseResults(data): void {
    this.tasqResponseResults = data;
  }

  @Mutation
  setSignalChartTime(data): void {
    this.signalChartTime = data;
  }

  @Mutation
  setFromNowBack(data): void {
    this.fromNowBack = data;
  }

  @Mutation
  setPadNestedTasqs(data) {
	  this.padNestedTasqs = data;
  }

  @Mutation
  setIsLoadingTasqResponses(data) {
	  this.isLoadingTasqResponses = data;
  }

  @Mutation
  setKanbanSelectedTasq(tasqId): void {
    this.kanbanSelectedTasq = tasqId;
  }

  @Mutation
  setAdditionalPadNestedTasqs(data) {
	  this.additionalPadNestedTasqs = data;
  }

  @Mutation
  updateAdditionalPadNestedTasqs(data) {
	  this.additionalPadNestedTasqs = this.additionalPadNestedTasqs.concat(data);
  }

  @Mutation
  setTasqList({ data, listType = 'tasqList', concat = false }: {
    data: TasqJob[],
    listType?: string,
    concat?: boolean,
  }): void {
    if (concat) {
      this[listType] = this[listType].concat(data);
    } else {
      this[listType] = data;
    }
    this.allTasqs = this.allTasqs.concat(data);
  }

  @Mutation
  setProducingWells(data): void {
    this.producingWells = data;
  }

  @Mutation
  setSubmittedTasqs(data): void {
    this.submittedTasqs = data;
  }

  @Mutation
  setPaginationLoading(value): void {
    this.paginationLoading = value;
  }

  @Mutation
  setChartFromTime(value): void {
    // console.log(value)
    // eslint-disable-next-line prefer-destructuring
    this.chartFromTime = value.split('T')[0];
  }

  @Mutation
  setChartToTime(value): void {
    // console.log(value)
    // eslint-disable-next-line prefer-destructuring
    this.chartToTime = value.split('T')[0];
  }

  @Mutation
  setShowTasqDetailsMobile(value): void {
    this.showTasqDetailsMobile = value;
  }

  @Mutation
  setsaveOffline(value): void {
    console.log(value);
    this.savingForOffline = value;
  }

  @Mutation
  @Debounce(200)
  updateTasqList({ data, listType = 'tasqList' }: {
    data: TasqJob[],
    listType?: string,
  }): void {
    if (listType === 'tasqList') {
      data.forEach((tasq) => {
        if (tasq.id) {
          const foundIndex = this[listType].findIndex(((obj, k) => obj.id === tasq.id));
          if (foundIndex > -1) {
            this[listType][foundIndex] = tasq;
          } else {
            this[listType].push(tasq);
          }
        }
      });
    }
    this.allTasqs = this.allTasqs.concat(data);
  }

  @Mutation
  updateTasqInList({ data, listType = 'tasqList' }: {
    data: TasqJob,
    listType?: string,
  }): void {
    if (listType === 'tasqList') {
      if (data.id) {
        let completedTasq = false;

        if (data.completedForToday || data.completed) {
          completedTasq = true;
          if (this.tasqListLevel === 'Well') {
            this.isLoadingTasqs = true;
          }
        }

        // console.log(data);

        if (this.tasqListLevel !== 'Pad') {
          const foundIndexInTasqList = this[listType].findIndex(((obj, k) => obj.id === data.id));
          if (foundIndexInTasqList > -1) {
            if (!completedTasq) {
              this[listType].splice(foundIndexInTasqList, 1);
              this[listType].push(data);
            } else {
              // console.log('I in complete');
              this[listType].splice(foundIndexInTasqList, 1);

              // const foundIndexInTasqList3 = this[listType].findIndex(((obj, k) => obj.id === data.id));
              // console.error(foundIndexInTasqList3);

              const foundIndexInCompleteList = this.completedTasqList.findIndex(((obj, k) => obj.id === data.id));

              if (foundIndexInCompleteList > -1) {
                this.completedTasqList[foundIndexInCompleteList] = data;
              } else {
                this.completedTasqList.push(data);
              }
            }
          } else {
            this[listType].push(data);
          }

          const foundIndexInAllTasqList = this.allTasqs.findIndex(((obj, k) => obj.id === data.id));
          if (foundIndexInAllTasqList > -1) {
            this.allTasqs[foundIndexInAllTasqList] = data;
          } else {
            this.allTasqs.push(data);
          }
        } else {
          const foundIndexInPadList = this[listType].findIndex(((obj, k) => obj.padName === data.padName));
          if (foundIndexInPadList > -1) {
            const data3 = this[listType][foundIndexInPadList].wells;
            if (data3) {
              // const found2 = data3.find((well) => well.id === data.id);
              const found = data3.findIndex((well) => well.id === data.id);
              if (found > -1) {
                if (!completedTasq) {
                  this[listType][foundIndexInPadList].wells.splice(found, 1);
                  this[listType][foundIndexInPadList].wells.push(data);
                } else {
                  this[listType][foundIndexInPadList].wells.splice(found, 1);
                  // eslint-disable-next-line no-unused-expressions
                  const newDataPayload = data;
                  newDataPayload.predictionType = 'Producing';
                  newDataPayload.overriddenName = 'Producing';
                  this[listType][foundIndexInPadList].wells.push(newDataPayload);

                  const foundIndexInCompleteList = this.completedTasqList.findIndex(((obj, k) => obj.id === data.id));

                  if (foundIndexInCompleteList > -1) {
                    this.completedTasqList[foundIndexInCompleteList] = data;
                  } else {
                    this.completedTasqList.push(data);
                  }
                }
              }
            }
          }
        }

        setTimeout(() => {
          this.isLoadingTasqs = false;
        }, 150);
      }
    }
    // this.allTasqs = this.allTasqs.concat(data);
  }

  @Mutation
  setUsernamesList(username) {
    if (username) {
      if (!this.usernamesList.includes(username)) {
        this.usernamesList.push(username);
      } else {
        const index = this.usernamesList.indexOf(username);
        if (index > -1) {
          this.usernamesList.splice(index, 1);
        }
      }
    }
  }

  @Mutation
  setUsernamesBulkList(usernames) {
    this.usernamesList = usernames;
  }

  @Mutation
  setTasqListLevel(level): void {
    console.log(level);
    this.tasqListLevel = level;
  }

  @Mutation
  setProgresPercentage(data): void {
    if (this.progressPercentage + data < 100) {
      this.progressPercentage += data;
    } else {
      // this.progressPercentage = 90;
    }

    // console.log( this.progressPercentage)
    if (this.progressPercentage >= 89) {
      setTimeout(() => {
        this.progressPercentage = 95;
      }, 5000);
      setTimeout(() => {
        this.progressPercentage = 0;
      }, 10000);
    }
  }

  @Mutation
  addProgresPercentage(data): void {
    this.progressPercentage = data;
  }

  @Mutation
  setWaitingTasqs(data): void {
    this.waitingTasqList = data;
  }

  @Mutation
  setActiveTasq(id: string): void {
    this.activeWorkTicket = null;
    let activeTasqDiv: any = null;

    activeTasqDiv = document.getElementById(id)!;
    if (activeTasqDiv && activeTasqDiv.previousSibling) {
      this.activeTasqScroll = activeTasqDiv.previousSibling.id;
    } else if (activeTasqDiv && activeTasqDiv.nextSibling) {
      this.activeTasqScroll = activeTasqDiv.nextSibling.id;
    }
    if (this.activeTasqScroll) {
      localStorage.setItem('ActiveTasqPageNo', this.currentTasqListPageNo);
      localStorage.setItem('ActiveTasqScroll', this.activeTasqScroll);
    }
    // this.activeTasqScroll = null;
    this.activeTasq = this.allTasqs.find((i) => i.id === id);

    if (!this.activeTasq) {
      this.activeTasq = this.waitingTasqList.find((i) => i.id === id);
    }
    if (!this.activeTasq) {
      this.activeTasq = [...workspaceModule.totalItems, ...this.tasqList, ...this.completedTasqList, ...this.waitingTasqList, ...this.padNestedTasqs, ...this.additionalPadNestedTasqs].find((t) => id && t.id.toLowerCase() === id.toLowerCase());
    }
    if (!this.activeTasq) {

    }
  }

  @Mutation
  setActiveWorkTicket(ticket): void {
    this.activeWorkTicket = ticket;
    this.activeTasq = ticket;
  }

  @Mutation
  setActiveTasqExplicit(tasq): void {
    this.activeTasq = tasq;
  }

  @Mutation
  setActiveFilterList(list: any): void {
    this.activeFilterList = list;
  }

  @Mutation
  setSubstringFilter(searchQuery: any): void {
    this.activeSubstringFilter = searchQuery;
  }

  @Mutation
  setPredictionTypeFilter(predictionTypes: any): void {
    this.activePredictionTypeFilter = predictionTypes;
  }

  @Mutation
  setDefermentDaysFilter(defermentDays: any): void {
    this.defermentDays = defermentDays;
  }

  @Mutation
  setCycleTimeDaysFilter(cycleTimeDays: any): void {
    this.cycleTimeDays = cycleTimeDays;
  }

  @Mutation
  setJobTypeFilter(jobTypeFilter: any): void {
    this.jobTypeFilter = jobTypeFilter;
  }

  @Mutation
  setAllPredictionTypes(predictionTypes: any): void {
    // eslint-disable-next-line no-param-reassign
    predictionTypes = predictionTypes.filter((e) => e !== 'state change' && e !== 'Label' && e !== 'Well down');
    predictionTypes.push('Off-Target Line Pressure');
    predictionTypes.sort();
    this.allPredictionTypes = predictionTypes;
  }

  @Mutation
  async setAllPredictionDistTypes(predictionTypes: any) {
    // eslint-disable-next-line no-param-reassign
    const updatedPredictionTypes = Object.keys(predictionTypes).filter((key) => predictionTypes[key]);
    // predictionTypes = predictionTypes.filter((e) => e !== 'state change' && e !== 'Label' && e !== 'Well down');
    updatedPredictionTypes.push('Off-Target Line Pressure');
    updatedPredictionTypes.sort();
    await userPreferenceDB.setItem('allPredictionTypes', updatedPredictionTypes);

    this.allPredictionTypes = updatedPredictionTypes;
  }

  @Mutation
  setInfiniteListScroll(scrollHeight: any): void {
    this.infiniteScrollHeight = scrollHeight;
  }

  @Mutation
  setTasqStartLimit(limit: any = 0): void {
    if (limit === 0) {
      this.tasqList = [];
      this.allTasqs = [];
    }
    this.tasqStartLimit = limit;
  }

  @Mutation
  resetPagination(): void {
    console.log('first');
    this.tasqList = [];
    this.allTasqs = [];
    this.tasqStartLimit = 0;
    this.tasqEndLimit = 30;
    this.tasqLimitPerPage = 30;
    this.tasqTotalPages = 1;
    this.currentTasqListPageNo = 1;
    this.todaysTasqLength = 0;
  }

  @Mutation
  setTotalTasqPagesLength(todaysTasqLength): void {
    this.tasqTotalPages = Math.ceil((todaysTasqLength) / this.tasqLimitPerPage);
    this.todaysTasqLength = todaysTasqLength;
  }

  @Mutation
  @Debounce(5000)
  updatePaginationDetails(): void {
    this.currentTasqListPageNo += 1;
    this.tasqStartLimit = this.tasqEndLimit + 1;
    this.tasqEndLimit = this.tasqStartLimit + this.tasqLimitPerPage;
  }

  @Mutation
  setActiveAreaFilter(areas): void {
    this.activeFilterArea = areas;
  }

  @Mutation
  setActiveTeamFilter(teams): void {
    this.activeFilterTeam = teams;
  }

  @Mutation
  setCycleTimeLessThanFilter(value): void {
    this.cycleTimeLessThanFilter = value;
  }

  @Mutation
  setCycleTimeGreaterThanFilter(value): void {
    this.cycleTimeGreaterThanFilter = value;
  }

  @Mutation
  setDefermentPercentLessThanFilter(value): void {
    this.defermentPercentLessThanFilter = value;
  }

  @Mutation
  setDefermentPercentGreaterThanFilter(value): void {
    this.defermentPercentGreaterThanFilter = value;
  }

  @Mutation
  setActiveRouteFilter(routes): void {
    this.activeFilterRoute = routes;
  }

  @Mutation
  setActiveWaitingOnFilter(waitingOn): void {
    this.activeFilterWaitingOn = waitingOn;
  }

  @Mutation
  setUnitsLessThanFilter(value): void {
    this.unitsLessThanFilter = value;
  }

  @Mutation
  setUnitsGreaterThanFilter(value): void {
    this.unitsGreaterThanFilter = value;
  }

  @Mutation
  setLocalFilter(value): void {
    this.useLocalFilter = value;
  }

  @Mutation
  resetSetActiveTasq(): void {
    this.activeTasq = null;
  }

  @Mutation
  updateTasq({ id, data }: { id: string, data: any }): void {
    const foundTasq = this.allTasqs.find((i) => i.id === id);
    Object.assign(foundTasq, data);
  }

  @Mutation
  setActiveList(listType: string): void {
    this.activeListType = listType;
  }

  @Mutation
  setActivePage(page: string): void {
    this.activePage = page;
  }

  @Mutation
  setIsLoadingTasqs(isLoadingTasqs: boolean): void {
    this.isLoadingTasqs = isLoadingTasqs;
  }

  @Mutation
  setIsLoadingPaginatedTasqs(isLoadingTasqs: boolean): void {
    this.isLoadingPaginatedTasqs = isLoadingTasqs;
  }

  @Mutation
  setIsLoadingSearchTasqs(isLoadingSearchTasqs: boolean): void {
    this.isLoadingSearchTasqs = isLoadingSearchTasqs;
  }

  @Mutation
  resetTasqListLevel() {
    this.setTasqListLevel(accountModule.user != null && accountModule.user.role != null && (accountModule.user.role == 'Operators' || accountModule.user.role == 'FieldOperator') ? 'Pad' : 'Well');
  }

  @Debounce(100)
  @Action
  async getTasqs(list: {
    text?: string,
    val: string,
  }): Promise<void> {
    let level = this.tasqListLevel;
    const isOnlineApp = await isOnline();
    try {
      if (!isOnlineApp) {
        if (this.tasqList && this.tasqList.length && this.producingWells && this.producingWells.length) {
          return;
        }
        this.prepareOfflineMode();
        return;
      }
      this.resetPagination();

      if (level === 'Pad') {
        await this.getTasqsForPadView();
        return;
      }
      if (!this.isLoadingTasqs) {
        this.setIsLoadingTasqs(true);
        this.setPaginationLoading(false);

        this.setTasqStartLimit();

        let todaysTasqResults: any[] = [];
        const completedTasqResults: any[] = [];
        let waitingTasqResults: any[] = [];
        if (this.activePage !== 'Tasq') {
          level = 'Well';
        }

        if (((level !== 'Pad') || this.activePage !== 'Tasq')) {
          let from = 0;

          let to = 499;

          const variables = {
            Timezone: 'America/Denver',
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
	          Level: 'Well',
            // @ts-ignore
            skipTotalTasqs: true,
            skipTodaysTasqs: false,
            To: to,
            // @ts-ignore
            From: from,
            // skipWaitingOn: false,
            // TasqStatus ["Open"]
            TasqStatus: ['Open'],
            ListType: 'Tasqs',

	  };
          if (level.toLowerCase() === 'wellview' && this.activePage === 'Tasq') {
            // variables.ListType = 'Well';
          }
          // console.log(variables)

          let tasqsLists: any = {};

          const {
            data: {
              get_tasq_list: tasqsListsResponse,
            },
          }: GetTasqsListsResponse = await predictionsApolloClient.query({
            query: getTasqsLists,
            variables,
            fetchPolicy: 'network-only',
          });

          tasqsLists = tasqsListsResponse;
          // console.log(tasqsLists.PredictionTypes);
          // if (tasqsLists.PredictionTypes && typeof tasqsLists.PredictionTypes === 'string') {
          //   this.setAllPredictionTypes(JSON.parse(tasqsLists.PredictionTypes));
          // } else {
          //   this.setAllPredictionTypes((tasqsLists.PredictionTypes));
          // }

          if (tasqsLists.PredictionTypesDict && typeof tasqsLists.PredictionTypesDict === 'string') {
            this.setAllPredictionDistTypes(JSON.parse(tasqsLists.PredictionTypesDict));
          }

          // @ts-ignore
          todaysTasqResults = tasqsLists.TodaysTasqResults;

          // @ts-ignore
          waitingTasqResults = tasqsLists.WaitingOnTasqs;

          // @ts-ignore
          // completedTasqResults = tasqsLists.CompletedTasqs;

          const tasqsList = (todaysTasqResults).map(
            (t: any) => mapDefaultTasq(jsonParse(t)),
          );

          // // console.log(tasqsList);
          // const completed = (completedTasqResults).map(
          //   (t: any) => mapDefaultTasq(jsonParse(t)),
          // );

          const waitingOn = (waitingTasqResults).map(
            (t: any) => mapDefaultTasq(jsonParse(t)),
          );

          this.setWaitingTasqs(waitingOn);

          this.setTasqList({
            data: tasqsList.concat(waitingOn),
          });
          // this.setTasqList({
          //   data: completed,
          //   listType: 'completedTasqList',
          // });
          this.setIsLoadingTasqs(false);
          this.setActiveList(list.val);
          // this.setIsLoadingTasqs(false);
          this.setPaginationLoading(false);

          this.setTasqListLevel(level);

          if (this.savingForOffline) {
            await userPreferenceDB.setItem('tasqListPaginated', this.tasqList);
          }

          if (Number(tasqsLists.TodaysTasqLength) > 500) {
            const counter = Math.ceil((Number(tasqsLists.TodaysTasqLength) - 499) / 500);

            this.setIsLoadingPaginatedTasqs(true);

            for (let index = 0; index < counter; index++) {
              from = to + 1;
              to += 500;

              const listType = 'Tasqs';

              if (level.toLowerCase() === 'wellview' && this.activePage === 'Tasq') {
              // listType = 'Well';
              }
              const todaysTasqResultsPaginated: any = [];

              if (navigator.onLine) {
                predictionsApolloClient.query({
                  query: getTasqsLists,
                  variables: {
                    Timezone: 'America/Denver',
                    Operator: getConfigEnv('OPERATOR_LOWERCASED'),
                    Level: 'Well',
                    // @ts-ignore
                    skipTotalTasqs: true,
                    skipTodaysTasqs: false,
                    To: to,
                    // @ts-ignore
                    From: from,
                    // skipWaitingOn: false,
                    // TasqStatus ["Open"]
                    TasqStatus: ['Open'],
                    ListType: listType,

                  },
                  fetchPolicy: 'network-only',
                }).then(async (result: any) => {
                  const { data: { get_tasq_list: paginatedTasqsLists } } = result;

                  for (let x = 0; x < paginatedTasqsLists.TodaysTasqResults.length; x++) {
                    todaysTasqResultsPaginated.push(JSON.parse(paginatedTasqsLists.TodaysTasqResults[x]));
                  }

                  const tasqListPaginated = jsonParse(JSON.stringify(todaysTasqResultsPaginated)).map(
                    (t: TasqJobResponse) => mapDefaultTasq(t),
                  );

                  // console.log(tasqListPaginated);

                  this.setTasqList({
                    data: tasqListPaginated,
                    concat: true,
                  });
                  if (this.savingForOffline) {
                    console.log(`tasqListPaginated${index}`);
                    if (this.tasqList) {
                      console.log(this.tasqList.length);
                      await userPreferenceDB.setItem('tasqListPaginated', this.tasqList);
                    }
                  }
                });
              } else {
                // eslint-disable-next-line no-await-in-loop
                const tasqListPaginated: any = await userPreferenceDB.getItem(`tasqListPaginated${index}`);

                if (tasqListPaginated && tasqListPaginated.length) {
                  this.setTasqList({
                    data: tasqListPaginated,
                    concat: true,
                  });
                }
              }
            }

            sleep(5000).then(() => {
              this.setIsLoadingPaginatedTasqs(false);
            });

            // paginatedPromise = (userPreferenceDB.getItem('getTasqsListPaginated'))

            await sleep(1000);
          }
        }
        this.setIsLoadingTasqs(false);
        // this.setPaginationLoading(true);

        this.setTasqListLevel(level);
        if ((level !== 'Pad' && this.activePage === 'Tasq' && this.producingWells.length < 3)) {
          this.setIsLoadingPaginatedTasqs(true);
          let producingPromise: any = null;

          if (isOnlineApp) {
            producingPromise = (predictionsApolloClient.query({
              query: searchTasqs,
              variables: {
                skipTotalTasqs: false,
                skipTotalProducing: false,
                input: {
                  Timezone: 'America/Denver',
                  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
                  Level: 'Well',
                  FetchAllProducing: true,

                },

              },
              fetchPolicy: 'network-only',
            }));

            producingPromise.then(async (result: any) => {
              const { data: { search_tasqs: newSearchLists } } = result;
              const data: any = JSON.parse(newSearchLists.TotalProducing);

              const producing = data.map(
                (t: TasqJobResponse) => mapDefaultProducingTasq(t),
              );
              this.setProducingWells(producing);
              if (this.producingWells && this.producingWells.length) {
                await userPreferenceDB.setItem('getTasqsListProducing', producing);
              }
              this.setIsLoadingPaginatedTasqs(false);
            }).catch(() => {
              this.setIsLoadingPaginatedTasqs(false);
            });
          }

          // console.log(producingPromise)
        }

        await this.getCompletedTasqs();
      }
    } catch (error: any) {
      this.setIsLoadingPaginatedTasqs(false);
      console.log(error.message);
    }
  }

  @Action
  async getCompletedTasqs() {
    const listType = 'Tasqs';
    let completedTasqResults: any[] = [];

    let from = 0;

    let to = 499;
    predictionsApolloClient.query({
      query: getTasqsLists,
      variables: {
        Timezone: 'America/Denver',
        Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        Level: 'Well',
        To: to,
        // @ts-ignore
        From: from,
        // @ts-ignore
        skipTotalTasqs: true,
        skipTodaysTasqs: true,
        skipWaitingOn: true,
        // TasqStatus ["Open"]
        TasqStatus: ['Closed'],
        ListType: listType,

      },
      fetchPolicy: 'network-only',
    }).then(async (result: any) => {
      const { data: { get_tasq_list: tasqsLists } } = result;

      // @ts-ignore
      completedTasqResults = tasqsLists.CompletedTasqs;

      const completed = (completedTasqResults).map(
        (t: any) => mapDefaultTasq(jsonParse(t)),
      );

      this.setTasqList({
        data: completed,
        listType: 'completedTasqList',
      });

      if (Number(tasqsLists.CompletedTasqLength) > 500) {
        const counter = Math.ceil((Number(tasqsLists.CompletedTasqLength) - 499) / 500);

        for (let index = 0; index < counter; index++) {
          from = to + 1;
          to += 500;

          const todaysTasqResultsPaginated: any = [];
          predictionsApolloClient.query({
            query: getTasqsLists,
            variables: {
              Timezone: 'America/Denver',
              Operator: getConfigEnv('OPERATOR_LOWERCASED'),
              Level: 'Well',
              // @ts-ignore
              skipTotalTasqs: true,
              skipTodaysTasqs: false,
              To: to,
              // @ts-ignore
              From: from,
              // skipWaitingOn: false,
              // TasqStatus ["Open"]
              TasqStatus: ['Closed'],
              ListType: listType,

            },
            fetchPolicy: 'network-only',
          }).then(async (result: any) => {
            const { data: { get_tasq_list: paginatedTasqsLists } } = result;

            for (let x = 0; x < paginatedTasqsLists.CompletedTasqs.length; x++) {
              todaysTasqResultsPaginated.push(JSON.parse(paginatedTasqsLists.CompletedTasqs[x]));
            }

            const tasqListPaginated = jsonParse(JSON.stringify(todaysTasqResultsPaginated)).map(
              (t: TasqJobResponse) => mapDefaultTasq(t),
            );

            // console.log(tasqListPaginated);

            this.setTasqList({
              data: tasqListPaginated,
              listType: 'completedTasqList',
              concat: true,
            });
          });
        }

        sleep(5000).then(() => {
          this.setIsLoadingPaginatedTasqs(false);
        });

        // paginatedPromise = (userPreferenceDB.getItem('getTasqsListPaginated'))

        await sleep(1000);
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  @Debounce(100)
  @Action
  async getTasqsForPadView(): Promise<void> {
    this.resetPagination();

    let level = this.tasqListLevel;
    console.log(level);
    try {
      if (!this.isLoadingTasqs) {
        this.setIsLoadingTasqs(true);
        this.setPaginationLoading(false);

        this.setTasqStartLimit();

        let to = 99;

        let from = 0;

        let todaysTasqLength: any = 499;

        const todaysTasqResults: any[] = [];
        if (this.activePage !== 'Tasq') {
          level = 'Well';
        }

        if ((level === 'Pad')) {
          // @ts-ignore

          let searchLists: any;
          const offlinePadData = await localForage.getItem('saveOfflineMyPadList');
          if (navigator.onLine) {
            const {
              data: {
                search_tasqs: newSearchLists,
              },
            }: SearchTasqsResponse = await predictionsApolloClient.query({
              query: searchTasqs,
              variables: {
                skipTotalTasqs: false,
                skipTotalProducing: true,
                input: {
                  Timezone: 'America/Denver',
                  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
                  Level: level,
                  To: to,
                  // @ts-ignore
                  From: from,
                  FetchAllProducing: false,

                },
              },
              fetchPolicy: 'network-only',
            });
            searchLists = newSearchLists;
          } else {
            searchLists = offlinePadData;
          }

          this.setTasqListLevel(level);

          for (let x = 0; x < JSON.parse(searchLists.Results).length; x++) {
            todaysTasqResults.push(JSON.parse(searchLists.Results)[x]);
          }

          todaysTasqLength = searchLists.ResultTotal;

          // if (todaysTasqLength) {
          //   from = 500;
          //   to = Number(todaysTasqLength);
          // }

          this.setTotalTasqPagesLength(todaysTasqLength);
          const tasqsList = (todaysTasqResults).map(
            (t: TasqJobResponse) => mapDefaultTasq(t),
          );

          const additionalPadNestedTasqs: any[] = [];
          for (let t = 0; t < tasqsList.length; t++) {
            if (tasqsList[t].wells.length == 0) {
              continue;
            }
            for (let r = 0; r < tasqsList[t].wells.length; r++) {
              tasqsList[t].wells[r] = mapDefaultTasq(tasqsList[t].wells[r]);
              additionalPadNestedTasqs.push(tasqsList[t].wells[r]);
            }
          }

          this.setAdditionalPadNestedTasqs(additionalPadNestedTasqs);
          this.setTasqList({
            data: tasqsList,
          });
          this.setIsLoadingTasqs(false);
        // this.setPaginationLoading(true);
        }
        this.setIsLoadingTasqs(false);
        // this.setPaginationLoading(true);

        this.setTasqListLevel(level);

        if (todaysTasqLength > 100) {
          const counter = Math.ceil((Number(todaysTasqLength) - 99) / 100);

          for (let index = 0; index < counter; index++) {
            from = to + 1;
            to += 100;

            predictionsApolloClient.query({
              query: searchTasqs,
              variables: {
                skipTotalTasqs: false,
                skipTotalProducing: true,
                input: {
                  Timezone: 'America/Denver',
                  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
                  Level: level,
                  To: to,
                  // @ts-ignore
                  From: from,
                  FetchAllProducing: false,

                },
              },
              fetchPolicy: 'network-only',
            }).then((result: any) => {
              const { data: { search_tasqs: newSearchListPaginated } } = result;

              const data: any = JSON.parse(newSearchListPaginated.Results);

              const paginatedPads = data.map(
                (t: TasqJobResponse) => mapDefaultTasq(t),
              );

              const additionalPadNestedTasqs: any[] = [];
              for (let t = 0; t < paginatedPads.length; t++) {
                if (paginatedPads[t].wells.length == 0) {
                  continue;
                }
                for (let r = 0; r < paginatedPads[t].wells.length; r++) {
                  paginatedPads[t].wells[r] = mapDefaultTasq(paginatedPads[t].wells[r]);
                  additionalPadNestedTasqs.push(paginatedPads[t].wells[r]);
                }
              }

              this.updateAdditionalPadNestedTasqs(additionalPadNestedTasqs);

              this.setTasqList({
                data: paginatedPads,
                concat: true,
              });
            });
          }
        }
        this.setIsLoadingPaginatedTasqs(true);

        predictionsApolloClient.query({
          query: searchTasqs,
          variables: {
            skipTotalTasqs: false,
            skipTotalProducing: false,
            input: {
              Timezone: 'America/Denver',
              Operator: getConfigEnv('OPERATOR_LOWERCASED'),
              Level: 'Well',
              FetchAllProducing: true,

            },

          },
          fetchPolicy: 'network-only',
        }).then((result: any) => {
          const { data: { search_tasqs: newSearchLists } } = result;
          const data: any = JSON.parse(newSearchLists.TotalProducing);

          const producing = data.map(
            (t: TasqJobResponse) => mapDefaultProducingTasq(t),
          );
            // this.setProducingWells(producing);
          this.setPadNestedTasqs(producing);
          this.setIsLoadingPaginatedTasqs(false);
        }).catch(() => {
          this.setIsLoadingPaginatedTasqs(false);
        });
      }
    } catch (error: any) {
      this.setIsLoadingPaginatedTasqs(false);
      console.log(error.message);
    }
  }

  @Action
  async prepareOfflineMode() {
    console.log('Im offline mode');
    this.setIsLoadingPaginatedTasqs(true);
    const producingWells: any = await userPreferenceDB.getItem('getTasqsListProducing');
    const activeWells: any = await userPreferenceDB.getItem('tasqListPaginated');

    if (producingWells && producingWells.length) {
      this.setProducingWells(producingWells);
    }

    if (activeWells && activeWells.length) {
      console.log('active list lenght');
      console.log(activeWells.length);
      this.resetPagination();
      this.setTasqList({
        data: activeWells,
      });
    }

    this.setIsLoadingPaginatedTasqs(false);
  }

  @Action
  async getTasqsByPagination(list: { text?: string, val: string}) {
    const isOnlineApp = navigator.onLine;
    // console.log('pagination function called')
    // console.log(this.paginationLoading)
	  if (this.paginationLoading) {
      this.setPaginationLoading(false);
      if (this.currentTasqListPageNo <= this.tasqTotalPages || this.tasqStartLimit === 0) {
        const queryPrams = list;
        if (this.activePage === 'Tasq') {
          // let tasqUsernamesFilter: any = localStorage.getItem('TasqUsernamesFilter')
          // if (tasqUsernamesFilter && JSON.parse(tasqUsernamesFilter).length && !this.usernamesList.length) {
          // 	tasqUsernamesFilter =  JSON.parse(tasqUsernamesFilter)
          // 	this.setUsernamesBulkList(tasqUsernamesFilter)
          // }else {
          // //  this.setUsernamesList(accountModule.user.email)
          // }

          // const savedFilterValue: any = localStorage.getItem('tasqFilterValue');
          // if (savedFilterValue) {
          //   this.setSubstringFilter(savedFilterValue);
          // }

          // const savedActiveOptions = localStorage.getItem('tasqActiveOptions');
          // if (savedActiveOptions && JSON.parse(savedActiveOptions)) {
          //   this.setPredictionTypeFilter(JSON.parse(savedActiveOptions));
          // }
        }

        let level = this.tasqListLevel;
        if (this.activePage === 'Kanban') {
          level = 'Well';
        }

        // console.log(this.activeFilterRoute)

        const SubstringFilter = this.activeSubstringFilter;
        const variables = {
          Timezone: 'America/Denver',
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          Level: level,
          // @ts-ignore
          To: this.tasqEndLimit,
          // @ts-ignore
          From: this.tasqStartLimit,
          // @ts-ignore
          skipTotalTasqs: true,
          // skipWaitingOn: true,
          skipTodaysTasqs: true,
          ...(this.usernamesList.length && { Usernames: this.usernamesList }),
          ...((this.activeFilterTeam.length) && { Teams: this.activeFilterTeam }),
          ...((this.activeFilterRoute.length) && { Routes: this.activeFilterRoute }),
          ...((this.activeFilterArea.length) && { Areas: this.activeFilterArea }),
          ...((!isNaN(Number(this.cycleTimeGreaterThanFilter)) && Number(this.cycleTimeGreaterThanFilter)) && { CycleTimeGreaterThanFilter: Number(this.cycleTimeGreaterThanFilter) }),
          ...((!isNaN(Number(this.cycleTimeLessThanFilter)) && Number(this.cycleTimeLessThanFilter) !== 0) && { CycleTimeLessThanFilter: Number(this.cycleTimeLessThanFilter) }),
          ...((!isNaN(Number(this.unitsGreaterThanFilter)) && Number(this.unitsGreaterThanFilter) !== 0) && { UnitsGreaterThanFilter: Number(this.unitsGreaterThanFilter) }),
          ...((!isNaN(Number(this.unitsLessThanFilter)) && Number(this.unitsLessThanFilter) !== 0) && { UnitsLessThanFilter: Number(this.unitsLessThanFilter) }),
          TasqStatus: ['Open', 'Closed'],

        };

        if (this.activePage === 'Tasq' && this.usernamesList.length) {
          if (this.usernamesList && this.usernamesList[0] === []) {
            this.setUsernamesBulkList([]);
          }
          variables.Usernames = this.usernamesList;
        }

        if (this.activePredictionTypeFilter.length !== this.allPredictionTypes.length && this.activePredictionTypeFilter.length > 0) {
          // @ts-ignore
          variables.FilterByPredictionType = this.activePredictionTypeFilter;
        }

        this.setActiveFilterList(queryPrams);

        const todaysTasqResults: any[] = [];
        const completedTasqResults: any[] = [];
        const waitingTasqResults: any[] = [];

        if (this.currentTasqListPageNo === 1 || this.tasqStartLimit === 0 || !SubstringFilter) {
          if (!SubstringFilter && level === 'Well') {
            variables.skipTodaysTasqs = false;
          }

          if (this.currentTasqListPageNo === 1 || this.tasqStartLimit === 0) {
            this.setIsLoadingTasqs(true);
          }

          let tasqsLists : any = {};

          if (isOnlineApp) {
            const {
              data: {
                get_tasq_list: newTasqsLists,
              },
            }: GetTasqsListsResponse = await predictionsApolloClient.query({
              query: getTasqsLists,
              variables,
              fetchPolicy: 'network-only',
            });

            if (newTasqsLists) {
              tasqsLists = newTasqsLists;
            }
            // this.setIsLoadingTasqs(false);
            // @ts-ignore
          } else {
            const offlineTasqData = await localForage.getItem('saveOfflineMyTasqList');
            if (offlineTasqData) {
              // console.log(offlineTasqData);
              tasqsLists = offlineTasqData;
            }
          }

          if (tasqsLists.PredictionTypes) {
            this.setAllPredictionTypes(tasqsLists.PredictionTypes);
          }

          if (!SubstringFilter && tasqsLists.TodaysTasqResults && level === 'Well') {
            for (var x = 0; x < tasqsLists.TodaysTasqResults.length; x++) {
              todaysTasqResults.push(JSON.parse(tasqsLists.TodaysTasqResults[x]));
            }

            for (var x = 0; x < tasqsLists.WaitingOnTasqs.length; x++) {
              todaysTasqResults.push(JSON.parse(tasqsLists.WaitingOnTasqs[x]));
            }

            const tasqsList = jsonParse(JSON.stringify(todaysTasqResults)).map(
              (t: TasqJobResponse) => mapDefaultTasq(t),
            );

            // const waitingOn = jsonParse(JSON.stringify(waitingTasqResults)).map(
            //   (t: TasqJobResponse) => mapDefaultTasq(t),
            // );

            // this.setWaitingTasqs(waitingOn)

            const todaysTasqLength: any = tasqsLists.TodaysTasqLength;
            this.setTotalTasqPagesLength(todaysTasqLength);
            this.setTasqListLevel(level);

            this.updateTasqList({
              data: tasqsList,
              listType: 'tasqList',
            });
            this.setIsLoadingTasqs(false);
            this.setPaginationLoading(true);
          }

          if (tasqsLists.CompletedTasqs) {
            for (var x = 0; x < tasqsLists.CompletedTasqs.length; x++) {
              completedTasqResults.push(JSON.parse(tasqsLists.CompletedTasqs[x]));
            }
          }
          const completed = jsonParse(JSON.stringify(completedTasqResults)).map(
            (t: TasqJobResponse) => mapDefaultTasq(t),
          );

          this.setTasqList({
            data: completed,
            listType: 'completedTasqList',
          });
        }

        if ((SubstringFilter || level === 'Pad')) {
          // @ts-ignore
          delete variables.skipTotalTasqs;
          // @ts-ignore
          delete variables.skipTodaysTasqs;

          delete variables.TasqStatus;

          // @ts-ignore
          delete variables.Usernames;

          if (variables.Routes) {
            variables.FilterByRoutes = variables.Routes;
            delete variables.Routes;
          }

          let skipTotalTasqs = true;
          let skipTotalProducing = true;
          if (this.padNestedTasqs.length == 0) {
            skipTotalTasqs = false;
            skipTotalProducing = false;
          }

          const updatedVariables = {
            skipTotalTasqs,
            skipTotalProducing,
            input: {
              ...variables,
              ...(this.usernamesList.length && { Usernames: this.usernamesList }),
            },
          };
          let searchLists: any;
          const offlinePadData = await localForage.getItem('saveOfflineMyPadList');
          if (isOnlineApp) {
            const {
              data: {
                search_tasqs: newSearchLists,
              },
            }: SearchTasqsResponse = await predictionsApolloClient.query({
              query: searchTasqs,
              variables: updatedVariables,
              fetchPolicy: 'network-only',
            });
            searchLists = newSearchLists;
          } else {
            searchLists = offlinePadData;
          }

          this.setTasqListLevel(level);

          for (let i = 0; i < JSON.parse(searchLists.Results).length; i++) {
            todaysTasqResults.push(JSON.parse(searchLists.Results)[i]);
          }
          const tasqsList = jsonParse(JSON.stringify(todaysTasqResults)).map(
            (t: TasqJobResponse) => mapDefaultTasq(t),
          );

          const additionalPadNestedTasqs: any[] = [];
          for (let t = 0; t < tasqsList.length; t++) {
            if (tasqsList[t].wells.length == 0) {
              continue;
            }
            for (let r = 0; r < tasqsList[t].wells.length; r++) {
              tasqsList[t].wells[r] = mapDefaultTasq(tasqsList[t].wells[r]);
              additionalPadNestedTasqs.push(tasqsList[t].wells[r]);
            }
          }

          this.setAdditionalPadNestedTasqs(additionalPadNestedTasqs);
          if (this.padNestedTasqs.length == 0) {
            const padNestedTasqs: any[] = [];
            const nodeIDsAdded: string[] = [];
            const total_tasqs = JSON.parse(searchLists.TotalTasqs);
            for (let t = 0; t < total_tasqs.length; t++) {
              const mapped_tasq = mapDefaultTasq(total_tasqs[t]);
              padNestedTasqs.push(mapped_tasq);
              nodeIDsAdded.push(mapped_tasq.wellName);
            }
            const producing_tasqs = JSON.parse(searchLists.TotalProducing);
            for (let r = 0; r < producing_tasqs.length; r++) {
              const mapped_tasq = mapDefaultTasq(producing_tasqs[r]);

              if (!nodeIDsAdded.includes(mapped_tasq.wellName)) {
                padNestedTasqs.push(mapped_tasq);
              }
            }

            this.setPadNestedTasqs(padNestedTasqs);
          }
          this.setTasqListLevel(level);

          this.updateTasqList({
            data: tasqsList,
          });
          this.setIsLoadingTasqs(false);
          this.setPaginationLoading(true);
        }
        this.setActiveList(list.val);
        this.updatePaginationDetails();
        this.setIsLoadingTasqs(false);

        this.setTasqListLevel(level);
      }
      this.setPaginationLoading(true);
    }
  }

  @Action
  async getTasq({
	  PredictionID,
  }) {
    const {
      data: {
        get_tasq: {
          prediction: Prediction,
        },
      },
    } = await workflowApolloClient.query({
      query: getTasq,
      variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
        prediction_id: PredictionID,
      },
      fetchPolicy: 'network-only',
    });
    const tasq = mapDefaultTasq(JSON.parse(Prediction));
    this.updateTasqDetails(tasq);
    this.setActiveTasqExplicit(tasq);

    // this.setWaitingTasqs(waitingTasqs);
  }

  @Action
  async getRawTasq({
	  PredictionID,
  }) {
    const {
      data: {
        get_tasq: {
          prediction: Prediction,
        },
      },
    } = await workflowApolloClient.query({
      query: getTasq,
      variables: {
        operator: getConfigEnv('OPERATOR_LOWERCASED'),
        prediction_id: PredictionID,
      },
      fetchPolicy: 'network-only',
    });
    const tasq = mapDefaultTasq(JSON.parse(Prediction));
    this.updateTasqInList({ data: tasq });
    return (tasq);

    // this.setWaitingTasqs(waitingTasqs);
  }

  @Action
  async getWaitList() {
    const isOnlineApp = await isOnline();
    if (isOnlineApp) {
      const {
        data: {
          get_my_waitlist: {
            results: waitListResults,
          },
        },
      } = await workflowApolloClient.query({
        query: getMyWaitList,
        variables: {
          input: {
		  Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
        fetchPolicy: 'network-only',
      });
      const waitingTasqs = waitListResults.map(
        (r) => mapDefaultTasq(jsonParse(r)),
      );

      this.setWaitingTasqs(waitingTasqs);
      await userPreferenceDB.setItem('getWaitList', waitingTasqs);
    } else {
      const result = await userPreferenceDB.getItem('getWaitList');
      if (result) {
        this.setWaitingTasqs(result);
      }
    }
  }

  @Action
  async getTasqEventHistoryList(worklflowTaskID) {
    const isOnlineApp = navigator.onLine;
    this.setTasqEventHistoryList([]);
    if (isOnlineApp) {
      const {
        data: {
          get_tasq_event_history: {
            Results: tasqEventResults,
          },
        },
      } = await workflowApolloClient.query({
        query: getTasqEventHistory,
        variables: {
          input: {
            WorkflowTaskID: worklflowTaskID,
          },
        },
        fetchPolicy: 'network-only',
      });
      // console.log(tasqEventResults);
      this.setTasqEventHistoryList(tasqEventResults);
    }
  }

  @Action
  async getTasqListResponses() {
    const isOnlineApp = navigator.onLine;
    if (isOnlineApp) {
      // this.setIsLoadingTasqResponses(true);
      // const {
      //   data: {
      //     get_all_tasq_responses: {
      //       Results: tasqResponseResults,
      //     },
      //   },
      // } = await workflowApolloClient.query({
      //   query: getAllTasqResponses,
      //   variables: {
      //     input: {
      //       Operator: getConfigEnv('OPERATOR_LOWERCASED'),
      //     },
      //   },
      //   fetchPolicy: 'network-only',
      // });

      // const parsed_responses = JSON.parse(tasqResponseResults);

      // this.setTasqResponseResults(parsed_responses);
      this.setIsLoadingTasqResponses(false);
    }
  }

  @Action
  async getSubmittedForms(id: any = null) {
    try {
      const submittedFormResult: any = [];
      if (navigator.onLine) {
        const {
          data: {
            get_submitted_form_responses: submittedForms,
          },
		  } = await workflowApolloClient.query({
          query: getSubmittedForms,
          variables: {
            input: {
              Operator: getConfigEnv('OPERATOR_LOWERCASED'),

            },

          },
          fetchPolicy: 'network-only',
		  });

        //   console.log(JSON.parse(submittedForms.Results));
        // for (let x = 0; x < JSON.parse(submittedForms.Results).length; x++) {
        //   console.log(submittedForms.Results[x);
        //   submittedFormResult.push(submittedForms.Results[x]);
        // }

        const tasqsList = (JSON.parse(submittedForms.Results)).map(
          (t: TasqJobResponse) => mapDefaultTasq(t),
        );

        console.log(tasqsList);

        this.setSubmittedTasqs(tasqsList);

        await userPreferenceDB.setItem('submittedForms', tasqsList);

        console.log(submittedFormResult);
        return [];
      }

      const result = await userPreferenceDB.getItem('submittedForms');

      if (result) {
        this.setSubmittedTasqs(result);
        return result;
      }
      return [];
    //   }
    } catch (e) {
      const result = await userPreferenceDB.getItem('submittedForms');

      if (result) {
        this.setSubmittedTasqs(result);
        return result;
      }
      throw new Error(e);
    }
  }

  get notCompletedTasqs() {
    return this.tasqList;
  }

  get tasqById() {
    return (id: string): TasqJob|undefined => [...this.tasqList, ...this.producingWells, ...this.completedTasqList, ...this.waitingTasqList, ...this.padNestedTasqs, ...this.additionalPadNestedTasqs].find((t) => t.id?.toLowerCase() === id?.toLowerCase());
  }

  get activeTasqsList() {
    return this.activeListType;
  }

  // Work ticket codebase

  workTickets: any = []

  @Mutation
  setWorkTickets(data): void {
    this.workTickets = data;
  }

  @Action
  async getWorkTicketTasqs() {
    try {
      this.setIsLoadingTasqs(true);
      const {
        data: {
          get_work_ticket_tasqs: {
            Results: workTicketsResult,
          },
        },
      } = await workflowApolloClient.query({
        query: getWorkTickets,
        variables: {
          input: {
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
        fetchPolicy: 'network-only',
      });
      const workTicketList = (JSON.parse(workTicketsResult));
      const workTickets = workTicketList.map(
        (t: any) => mapDefaultWorkTicketTasqs(t),
      );
      this.setWorkTickets(workTickets);
      this.setIsLoadingTasqs(false);
    } catch (error) {
      console.error(error);
    }
  }

  @Action
  async getCountOpenTasqsForJobType() {
    try {
      const {
        data: {
          count_open_tasqs_for_job_type: {
            Count: tasqCount,
          },
        },
      }: any = await workflowApolloClient.query({
        query: getCountTasqForJobTypes,
        variables: {
          input: {
            TasqType: 'Work Ticket',
	          AssetName: 'ABE TROYER 4H',
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
        fetchPolicy: 'network-only',
      });
      console.log(tasqCount);
    } catch (error) {
      console.error(error);
    }
  }


  @Action
  async getCountOpenTasqsForAsset() {
    try {
      const {
        data: {
          count_open_tasqs_for_job_type: {
            Count: tasqCount,
          },
        },
      }: any = await workflowApolloClient.query({
        query: getCountTasqForAsset,
        variables: {
          input: {
            JobType: 'Housekeeping',
            AssetName: 'ABE TROYER 4H',
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            Area: 'OHIO - BLUE RACER',
          },
        },
        fetchPolicy: 'network-only',
      });
      console.log(tasqCount);
    } catch (error) {
      console.error(error);
    }
  }
}

export default getModule(TasqsListModule);
