const mapDefaultWorkTicketTasqs = (t, userEmail = ''): any => {
  const workTicket = {
    id: t.PredictionID || t.ID || t.NodeID || t.wellName,
    workflowTaskID: t.WorkflowTaskID || null,
    nodeID: t.NodeID,
    wellName: t.NodeID,
    padName: t.PadName,
    area: t.Area,
    status: t.Status || 'Open',
    route: t.Route,
    initialUserName: t.Assignee != null ? t.Assignee.initial_assignment : '',
    closed: t.Closed,
    detailedSteps: t.DetailedSteps,
    details: t.Details,
    files: t.Files || [],
    jobType: t.JobType,
    level: t.Level,
    manuallyAssigned: t.ManuallyAssigned === 'True',
    operator: t.Operator,
    predictionType: t.PredictionType,
    jobCategory: t.PredictionType,
    priority: t.Priority,
    requestCompletionDate: t.RequestCompletionDate,
    role: t.Role,
    requestDetails: t.RequestDetails,
    tasqType: t.TasqType,
    username: t.Username,
    bgColorClass: 'bg-tasqGrayDark',
    team: t.Team,
    time: t.time,
  };

  // @ts-ignore
  return workTicket;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  mapDefaultWorkTicketTasqs,
};
